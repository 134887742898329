.tabs .indicator {
  background-color:#000;
} /*Color of underline*/

.tabs .tab a{
  color: #039be5
}

.tabs .tab a:focus.active {
      background-color:#eee;
      color:#000;
} /*Background and text color when a tab is active*/

.tabs .tab a.active {
      background-color:#eee;
      color:#000;
} /*Background and text color when a tab is active*/

.tabs .tab a:hover {
      background-color:#eee;
      color:#039be5
  } /*Text color on hover*/
